// See here:
// https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html
import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import { sendSlackMessage } from '../lib/comms';

class ErrorBoundary extends React.PureComponent {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    let message = 'FATAL ERROR!\n\n';
    message += error.toString();
    message += '\n';
    message += info.componentStack.toString();
    message += '\n';
    message += window.location.href;
    sendSlackMessage(
      '#notifications-dsai',
      ':poop:',
      message,
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ margin: '0 auto', padding: 40 }}>
          <Paper style={{ padding: 20 }}>
            <h2>Something went wrong.</h2>
            <p>
              There seems to be an issue with
              our website right now. We have been notified of the issue
              and are on top of it. If this persists, please reach out at
              <a href="mailto:hello@deepshotai.com">hello@deepshotai.com</a>.
              We apologize for this inconvenience.
            </p>
          </Paper>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
