import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = () => (
  <div className="container">
    <Helmet>
      <title>404 Page Not Found</title>
    </Helmet>
    <h1>404 Not Found</h1>
    <p>
      The page you were looking for cannot be found. { ' ' }
      <Link to="/">Return home</Link>.
    </p>
  </div>
);

export default NotFound;
