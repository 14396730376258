import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth'

// TODO Currently there's only one configuration.

const PROD_CONF = {
  apiKey: 'AIzaSyDRGwY0F3921ff7Z30GkKEcL6mGuw7WAZE',
  authDomain: 'deepshot-ai.firebaseapp.com',
  databaseURL: 'https://deepshot-ai.firebaseio.com',
  projectId: 'deepshot-ai',
  storageBucket: 'deepshot-ai.appspot.com',
  messagingSenderId: '175999048240',
};

const DEV_CONF = {
  apiKey: 'AIzaSyDRGwY0F3921ff7Z30GkKEcL6mGuw7WAZE',
  authDomain: 'deepshot-ai.firebaseapp.com',
  databaseURL: 'https://deepshot-ai.firebaseio.com',
  projectId: 'deepshot-ai',
  storageBucket: 'deepshot-ai.appspot.com',
  messagingSenderId: '175999048240',
};

const config = process.env.NODE_ENV === 'production' ? PROD_CONF : DEV_CONF;

export const firebaseApp = firebase.initializeApp(config);
export const db = firebaseApp.database();
export default firebase;
