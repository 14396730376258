import {
  SET_NUM_LINEUPS,
  SET_MAX_EXP,
  SET_NOISE,
  LOCK_PLAYER,
  UNLOCK_PLAYER,
  BLOCK_PLAYER,
  UNBLOCK_PLAYER,
  SET_OPT_ERROR,
  SET_MIN_BOOM,
  SET_MAX_BUST,
  SET_BUILD_SETTINGS,
} from '../actions/types';

const INITIAL_STATE = {
  numLineups: 1,
  maxExp: 100,
  noise: 2,
  lockedPlayers: [],
  blockedPlayers: [],
  optError: '',
  minBoom: 0,
  maxBust: 100,
  buildSettings: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NUM_LINEUPS:
      return { ...state, numLineups: action.payload };
    case SET_MAX_EXP:
      return { ...state, maxExp: action.payload };
    case SET_NOISE:
      return { ...state, noise: action.payload };
    case BLOCK_PLAYER:
      const blockedPlayers = state.blockedPlayers || [];
      blockedPlayers.push(action.payload);
      const deduped = [...new Set(blockedPlayers)];
      return { ...state, blockedPlayers: deduped };
    case UNBLOCK_PLAYER:
      const removed = state.blockedPlayers.filter(p => p !== action.payload);
      return { ...state, blockedPlayers: removed };
    case LOCK_PLAYER:
      const lockedPlayers = state.lockedPlayers || [];
      lockedPlayers.push(action.payload);
      const dedupedLock = [...new Set(lockedPlayers)];
      return { ...state, lockedPlayers: dedupedLock };
    case UNLOCK_PLAYER:
      const removedLock = state.lockedPlayers.filter(p => p !== action.payload);
      return { ...state, lockedPlayers: removedLock };
    case SET_OPT_ERROR:
      return { ...state, optError: action.payload };
    case SET_MIN_BOOM:
      return { ...state, minBoom: action.payload };
    case SET_MAX_BUST:
      return { ...state, maxBust: action.payload };
    case SET_BUILD_SETTINGS:
      return { ...state, buildSettings: action.payload };
    default:
      return state;
  }
};
