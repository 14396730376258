import {
  SET_SELECTED_SLATE,
  SET_SLATES,
  SET_TEAMS,
  SET_POSITIONS,
  TOGGLE_SELECTED_POSITION,
  TOGGLE_SELECTED_TEAM,
  SET_SALARY_VALUE,
  TOGGLE_SPLIT_BY_VALUE,
  SET_GAMES,
  SET_SELECTED_TEAMS,
  SET_IS_LOADING,
} from '../actions/types';

const INITIAL_STATE = {
  selectedSlate: '0',
  slates: null,
  games: null,
  teams: null,
  positions: null,
  selectedPositions: [],
  selectedTeams: [],
  salaryLower: 3000,
  salaryUpper: 16000,
  salaryValue: [3000, 16000],
  splitByValue: true,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_SLATE:
      return { ...state, selectedSlate: action.payload };
    case SET_SLATES:
      return { ...state, slates: action.payload };
    case SET_TEAMS:
      return { ...state, teams: action.payload, selectedTeams: action.payload };
    case SET_POSITIONS:
      return { ...state, positions: action.payload, selectedPositions: action.payload };
    case TOGGLE_SELECTED_POSITION:
      const { selectedPositions } = state;
      let newSelectedPositions;
      if (selectedPositions.indexOf(action.payload) > -1) {
        newSelectedPositions = selectedPositions.filter(pos => pos !== action.payload);
      } else {
        newSelectedPositions = [...selectedPositions, action.payload];
      }
      return { ...state, selectedPositions: newSelectedPositions };
    case TOGGLE_SELECTED_TEAM:
      const { selectedTeams } = state;
      let newSelectedTeams;
      if (selectedTeams.indexOf(action.payload) > -1) {
        newSelectedTeams = selectedTeams.filter(team => team !== action.payload);
      } else {
        newSelectedTeams = [...selectedTeams, action.payload];
      }
      return { ...state, selectedTeams: newSelectedTeams };
    case SET_SALARY_VALUE:
      return { ...state, salaryValue: action.payload };
    case SET_GAMES:
      return { ...state, games: action.payload };
    case TOGGLE_SPLIT_BY_VALUE:
      return { ...state, splitByValue: !state.splitByValue };
    case SET_SELECTED_TEAMS:
      return { ...state, selectedTeams: action.payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
