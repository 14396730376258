import { combineReducers } from 'redux';
import { firebaseStateReducer as firebase } from 'react-redux-firebase';
import projectionFilters from './projectionFilters';
import optimizer from './optimizer';

const rootReducer = combineReducers({
  firebase,
  projectionFilters,
  optimizer,
});

export default rootReducer
