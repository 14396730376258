import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Loadable from 'react-loadable';
import './App.css';

import ErrorBoundary from './components/ErrorBoundary';
import NotFound from './pages/NotFound';
import Loading from './components/Loading';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#543e88',
    },
    secondary: {
      main: '#ea6bc8',
      contrastText: '#ffffff',
    },
  },
});

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const AsyncLandingFull = Loadable({
  loader: () => import('./pages/Sunset'),
  loading: Loading,
});

const AsyncTopPickNewsletter = Loadable({
  loader: () => import('./components/newsletters/TopPicksNewsletter'),
  loading: Loading,
});

const AsyncAdmin = Loadable({
  loader: () => import('./pages/protected/admin/Admin'),
  loading: Loading,
});

const AsyncLandingPrem = Loadable({
  loader: () => import('./pages/LandingPagePremium'),
  loading: Loading,
});

const AsyncDashboard = Loadable({
  loader: () => import('./pages/protected/Dashboard'),
  loading: Loading,
});

const AsyncSignIn = Loadable({
  loader: () => import('./pages/SignIn'),
  loading: Loading,
});

const AsyncTOS = Loadable({
  loader: () => import('./pages/TOS'),
  loading: Loading,
});

const AsyncPrivacy = Loadable({
  loader: () => import('./pages/Privacy'),
  loading: Loading,
});

const App = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <ErrorBoundary>
      <div>
        <Route component={ScrollToTop} />
        <Switch>
        {
          // <Route exact path="/sign-up" component={AsyncLandingFull} />
          // <Route exact path="/start" component={AsyncLandingPrem} />
          // <Route exact path="/" component={AsyncLandingFull} />
          // <Route exact path="/sign-in" component={AsyncSignIn} />
          // <Route path="/app" component={AsyncDashboard} />
          // <Route exact path="/terms-of-service" component={AsyncTOS} />
          // <Route exact path="/privacy-policy" component={AsyncPrivacy} />
          // <Route path="/admin" component={AsyncAdmin} />
          // <Route exact path="/newsletters/top-picks-nba" component={AsyncTopPickNewsletter} />
        }
          <Route path="*" component={AsyncLandingFull} status={404} />
        </Switch>
      </div>
    </ErrorBoundary>
  </MuiThemeProvider>
);

export default App;
