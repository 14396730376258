const serverUrl = 'https://us-central1-coastline-new-driver.cloudfunctions.net/api';

export const sendSlackMessage = (channel, icon, message) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Not sending slack message\n', message);
    return Promise.resolve();
  }

  const payload = {
    username: 'Deepshot Notification',
    icon_emoji: icon,
    channel,
    text: message,
  };

  return fetch(`${serverUrl}/slackMessage`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};
