export const SET_SELECTED_SLATE = 'SET_SELECTED_SLATE';
export const SET_SLATES = 'SET_SLATES';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_POSITIONS = 'SET_POSITIONS';
export const TOGGLE_SELECTED_POSITION = 'SET_POSITION';
export const TOGGLE_SELECTED_TEAM = 'SET_TEAM';
export const SET_SALARY_LOWER = 'SET_SALARY_LOWER';
export const SET_SALARY_UPPER = 'SET_SALARY_UPPER';
export const SET_SALARY_VALUE = 'SET_SALARY_VALUE';
export const TOGGLE_SPLIT_BY_VALUE = 'SET_SPLIT_BY_VALUE';
export const SET_GAMES = 'SET_GAMES';
export const SET_SELECTED_TEAMS = 'SET_SELECTED_TEAMS';
export const SET_NUM_LINEUPS = 'SET_NUM_LINEUPS';
export const SET_MAX_EXP = 'SET_MAX_EXP';
export const SET_NOISE = 'SET_NOISE';
export const LOCK_PLAYER = 'LOCK_PLAYER';
export const UNLOCK_PLAYER = 'UNLOCK_PLAYER';
export const BLOCK_PLAYER = 'BLOCK_PLAYER';
export const UNBLOCK_PLAYER = 'UNBLOCK_PLAYER';
export const SET_OPT_ERROR = 'SET_OPT_ERROR';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_MIN_BOOM = 'SET_MIN_BOOM';
export const SET_MAX_BUST = 'SET_MAX_BUST';
export const SET_BUILD_SETTINGS = 'SET_BUILD_SETTINGS';
